import React, {Component} from "react";
import { Chart } from "react-google-charts";
import {listen, dispatchEvent, EVENTS} from "../utils";
import { Slider, Handles } from 'react-compound-slider';

const defaultValues = [50]
const sliderStyle = {  // Give the slider some width
    position: 'relative',
    width: '100%',
    height: 35,

}

const railStyle = {
    position: 'absolute',
    width: '100%',
    height: 10,
    marginTop: 10,
    borderRadius: 5,
    backgroundColor: '#8B9CB6',
}

export default class extends Component{
    constructor(props){
        super(props);
        this.state = {
            speed: 50,
            history: [0],
            values: defaultValues.slice(),
            update: defaultValues.slice(),
        };

        this.mapId = props.mapId;
        this.onChange = onChange.bind(this);
        this.onHistory = onHistory.bind(this);

        listen(EVENTS.powerHeartCadance(this.mapId), (data)=>{
            let arr = data.detail.data;
            let speed = data.detail.speed;
            let history = [data.detail.history];
            this.setState({
                power: toChartData(arr[0], "watts") ,
                heart: toChartData(arr[1], "bpm"),
                cadence: toChartData(arr[2], "rpm"),
                speed: toChartData(arr[3], "km/h"),
                anSpeed: speed, history: history});
        });
    }

    render(){
        if(this.state.power) {
            const {
                state: { values, update },
            } = this
            return (
                <div className="gauge-charts">
                    <div className="gauge-speed">
                        <Chart
                            chartType="Gauge"
                            loader={<div>Loading Chart </div>}
                            data={this.state.speed}
                            options={options("speed", this.state.speed)}

                        />
                    </div>
                    <div className="gauge-power">
                        <Chart
                            chartType="Gauge"
                            loader={<div>Loading Chart </div>}
                            data={this.state.power}
                            options={options("power", this.state.speed)}

                        />
                    </div>
                    <div className="gauge-heart">
                        <Chart
                            chartType="Gauge"
                            loader={<div>Loading Chart </div>}
                            data={this.state.heart}
                            options={options("hr", this.state.speed)}

                        />
                    </div>
                    <div className="gauge-cadence">
                        <Chart
                            chartType="Gauge"
                            loader={<div>Loading Chart </div>}
                            data={this.state.cadence}
                            options={options("cadence", this.state.speed)}

                        />
                    </div>
                    <div className="slider-div">
                        <Slider
                            rootStyle={sliderStyle}
                            domain={[0, 100]}
                            step={1}
                            mode={1}
                            values={[0]}
                            onChange={this.onChange}
                        >
                            <div style={railStyle} />
                            <Handles>
                                {({ handles, getHandleProps }) => (
                                    <div className="slider-handles">
                                        {handles.map(handle => (
                                            <Handle
                                                key={handle.id}
                                                handle={handle}
                                                getHandleProps={getHandleProps}
                                            />
                                        ))}
                                    </div>
                                )}
                            </Handles>
                        </Slider>

                        <Slider
                            rootStyle={sliderStyle}
                            domain={[0, 100]}
                            step={1}
                            mode={1}
                            values={this.state.history}
                            onChange={this.onHistory}
                        >
                            <div style={railStyle} />
                            <Handles>
                                {({ handles, getHandleProps }) => (
                                    <div className="slider-handles">
                                        {handles.map(handle => (
                                            <Handle
                                                key={handle.id}
                                                handle={handle}
                                                getHandleProps={getHandleProps}
                                            />
                                        ))}
                                    </div>
                                )}
                            </Handles>
                        </Slider>
                    </div>
                </div>
            );
        }
        return <></>
    }
}

function Handle({ // your handle component
                    handle: { id, value, percent },
                    getHandleProps
                }) {
    return (
        <div
            style={{
                left: `${percent}%`,
                position: 'absolute',
                marginLeft: -15,
               // marginTop: 25,
                marginTop: 0,
                zIndex: 2,
                width: 30,
                height: 30,
                border: 0,
                textAlign: 'center',
                cursor: 'pointer',
                borderRadius: '50%',
                backgroundColor: '#2C4870',
                color: '#333',
            }}
            {...getHandleProps(id)}
        >
            <div style={{ fontFamily: 'Roboto', color: "white", fontSize: 11, marginTop: 7 }}>
                {value}
            </div>
        </div>
    )
}

function onChange(values){
    if(!isNaN(values)) {
        let v = values[0];
        //v = 1000 - v * 10;
        dispatchEvent(EVENTS.mapAnimationSpeed(this.mapId), v);
    }
}

function onHistory(values) {
    if(!isNaN(values)) {
        dispatchEvent(EVENTS.visualHistory(this.mapId), values[0]);
    }
}

const toChartData = (value, label) => {
    return [
        ['Label', 'Value'],
        [label, value]
    ]
}

const options = (key, speed) => {
    switch (key){
        case "power":
            return {
                width: 400, height: 120,
                redFrom: 400, redTo: 700,
                yellowFrom: 300, yellowTo: 400,
                greenFrom: 150, greenTo: 300,
                max: 700,
                minorTicks: 5,
                animation: {duration: speed}
            };
        case "hr":
            return {
                width: 400, height: 120,
                redFrom: 160, redTo: 220,
                yellowFrom: 120, yellowTo: 160,
                greenFrom: 60, greenTo: 120,
                max: 220,
                minorTicks: 5,
                animation: {duration: speed}
            }
        case "cadence":
            return {
                width: 400, height: 120,
                redFrom: 100, redTo: 140,
                yellowFrom: 80, yellowTo: 100,
                greenFrom: 60, greenTo: 80,
                max: 140,
                minorTicks: 5,
                animation: {duration: 50}
            }

        case "speed":
            return {
                width: 400, height: 120,
                redFrom: 41, redTo: 100,
                yellowFrom: 31, yellowTo: 40,
                greenFrom: 20, greenTo: 30,
                max: 100,
                minorTicks: 5,
                animation: {duration: 50}
            }
    }
}