import React, {Component} from "react";
import {Table, Button} from 'react-bootstrap';

import {dispatchEvent, EVENTS, secToInterval} from "../utils";
import TrackLoader, {_c} from "../../model/trackLoader";





export default class extends Component{
    constructor(props){
        super(props);
        this.state = {};
        this.showLaps = showLaps.bind(this);
        this.tableRows = tableRows.bind(this);
        this.showLap = showLap.bind(this);
    }

    componentDidMount(){
        (async ()=>{
            let data = await TrackLoader.instance().getLaps(this.props.mapId);
            this.setState({laps: data});
        })();

    }

    render(){

        return this.showLaps();
    }
}

function showLap (max, range){
    let min = max - Math.round(range);
    console.log("ssss: ", {min: min, max: max})
    dispatchEvent(EVENTS.lapSelection(this.props.mapId), {min: min, max: max});
}

function showLaps() {
    if(this.state.laps) {
        return <div className="laps-holder">
        <Table striped bordered hover size="sm" className="laps-table">
            <thead>
            <tr>
                <th><Button variant="link" size="sm" onClick={() => {this.showLap(0,0)}}>#</Button></th>
                <th>Time</th>
                <th>Power</th>
                <th>Cad</th>
                <th>HR</th>
            </tr>
            </thead>
            <tbody>
            {this.tableRows()}
            </tbody>
        </Table></div>;
    }else{
        return <></>
    }
}

function tableRows(){
    const data = this.state.laps;
    let order = 0;
    return data.map((row, key) =>{
            let timestamp = row[0];
            let time = Math.round(row[1]);
            let power = row[2];
            let hr = row[3];
            let cadence = row[4];
                return <tr key={key}>
                    <td><Button variant="link" size="sm" onClick={() => {this.showLap(timestamp, time)}}>{++order}</Button></td>
                    <td>{secToInterval(time)}</td>
                    <td>{power}</td>
                    <td>{cadence}</td>
                    <td>{hr}</td>
                </tr>
        }
    )
}