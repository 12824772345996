import React, {Component} from "react";
import m_e, {events} from "../../events/events";


import { css } from '@emotion/core';
// First way to import
import { DotLoader } from 'react-spinners';

export default class extends Component{
    constructor(props){
        super(props);
        this.m_e = m_e.getInstance();
        this.state = {};

        this.m_e.on(events.loading, ()=> {this.setState({loading:true})});
        this.m_e.on(events.loadingoff, ()=> {this.setState({loading:false})});
    }

    render(){
        if(this.state.loading){
            return (
                <div className="loading-holder">
                    <div className='loading'>
                        <DotLoader
                            sizeUnit={"px"}
                            size={150}
                            color={'#123abc'}
                            loading={this.state.loading}
                        />
                    </div>
                </div>
            );
        }else{
            return <></>
        }

    }
}