import React, {Component} from "react";
import Calendar from "./c/calendar";



export default class Home extends Component{

    state = {collapse:false};


    render(){
        return (
            <div  className="file-list">
                <Calendar  deleteFile={()=>{console.log("disabled")}} proccessFile={()=>{console.log("disabled")}} sid={0} />

            </div>
        )
    };
}
