import React, {Component} from "react";
import LineChart from "./c/googleLineChart";
import GuageCharts from "./c/gaugeCharts"
import Map from "./c/map";
import SelectionResult from "./selectionResult";
import Laps from "./c/laps";


export default class extends Component{

    constructor(props){
        super(props);

    }

    render(){
        let mapId = this.props.match.params.mapId;
        return (
            <>

            <div className="tools-div">
                <div className="tools-content">
                    <div className="tools-left">
                        <div className="tools-map">
                            <LineChart target="google-line-id" mapId={mapId}/>
                        </div>
                        <div className="track-map">
                            <Map divId={"map-" + mapId} source={mapId}/>
                            <GuageCharts mapId={mapId}/>
                        </div>
                    </div>
                    <div className="tools-right">
                        <SelectionResult source={mapId} />
                        <Laps mapId={mapId} />
                    </div>
                </div>
            </div>
            </>
        );
    }
}