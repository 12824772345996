import React, {Component} from "react";
import {GoogleCharts} from 'google-charts';
import TrackLoader, {_c} from "../../model/trackLoader";
import {listen, dispatchEvent, EVENTS, showLoading, hideLoading} from "../utils";

let counter = 0;

export default class extends Component{
    constructor(props){
        super(props);
        this.state = {};
        this.divId = props.target;
        this.chart = null;
        this.mapId = this.props.mapId;
        this.data = null;
        this.dataTable = null;
        this.visibleRange = null;
        this.showSelection = showSelection.bind(this);
        this.drawChart = this.drawChart.bind(this);
        this.initChart = this.initChart.bind(this);
        this.displaySelection = this.displaySelection.bind(this);
    }

    componentDidMount(){
        (async ()=>{
            showLoading("google-chart");
            this.data = await TrackLoader.instance().getAnalyseChart(this.props.mapId);
            GoogleCharts.load(this.initChart);
        })();

    }

    initChart(){
        const CONTAINER = document.getElementById(this.divId)

        this.chart = new GoogleCharts.api.visualization.LineChart(CONTAINER);

        this.dataTable = GoogleCharts.api.visualization.arrayToDataTable(this.data);

        GoogleCharts.api.visualization.events.addListener(this.chart, 'ready', ()=>{

            let observer = new MutationObserver((function () {
                var newRange = getChartRange(this.chart);
                if (JSON.stringify(this.visibleRange) !== JSON.stringify(newRange)) {
                    this.visibleRange = newRange;
                    let rangeKey;
                    let rangeFilter = [];
                    for(let colIndex = 0; colIndex < 2; colIndex ++){
                        rangeKey = (colIndex === 0) ? 'x' : 'y';
                        rangeFilter.push({
                            column: colIndex,
                            minValue: newRange[rangeKey].min,
                            maxValue: newRange[rangeKey].max
                        });
                    }

                    let rangeRows = this.dataTable.getFilteredRows(rangeFilter);

                    if(rangeRows.length > 1){
                        let selection = {trackId: this.props.mapId, min: rangeRows[0], max: rangeRows[rangeRows.length-1]};
                        dispatchEvent(EVENTS.chartSelection(this.props.mapId), selection);
                    }
                }
            }).bind(this));
            observer.observe(CONTAINER, {
                childList: true,
                subtree: true
            });
        });
        listen(EVENTS.powerHeartCadance(this.props.mapId), (data)=>{
            let altIndex = data.detail.altIndex;
            let altPosition = this.data[0].indexOf(_c.altitude);
            this.showSelection(altIndex, altPosition);
        });

        listen(EVENTS.lapSelection(this.props.mapId), (data)=>{
            let min = data.detail.min;
            let max = data.detail.max;
            this.displaySelection(min, max);
        });

        this.setState({chart: "ready"});
    }

    displaySelection(min, max){
        let view = new GoogleCharts.api.visualization.DataView(this.dataTable);

        if(min && max) {
            let newRange = newChartRange(min, max, this.chart);
            let rangeFilter = createRangeFilter(newRange)
            let rangeRows = this.dataTable.getFilteredRows(rangeFilter);
            view.setRows(rangeRows);
            this.chart.draw(view, getOptions([...this.data[0]]));
        }else{
            this.chart.draw(this.dataTable, getOptions([...this.data[0]]));
        }

    }

    drawChart() {
        if (this.dataTable) {
            this.chart.draw(this.dataTable, getOptions([...this.data[0]]));
        }
    }


    render(){

        return (
            <div className="google-chartline">
                <div id={this.divId}></div>
                {this.drawChart()}
            </div>
        );
    }
}

const createRangeFilter = (newRange) =>{
    let rangeFilter = [];
    let rangeKey;
    for(let colIndex = 0; colIndex < 2; colIndex ++){
        rangeKey = (colIndex === 0) ? 'x' : 'y';
        rangeFilter.push({
            column: colIndex,
            minValue: newRange[rangeKey].min,
            maxValue: newRange[rangeKey].max
        });
    }
    return rangeFilter;
}

const newChartRange = function(newX_min, newX_max, chart) {
    var chartLayout = chart.getChartLayoutInterface();
    var chartBounds = chartLayout.getChartAreaBoundingBox();
    return {
        x: {
            min: new Date(newX_min * 1000),
            max: new Date(newX_max * 1000)
        },
        y: {
            min: chartLayout.getVAxisValue(chartBounds.top + chartBounds.height),
            max: chartLayout.getVAxisValue(chartBounds.top)
        }
    };
}

const getChartRange = function(chart) {
    var chartLayout = chart.getChartLayoutInterface();
    var chartBounds = chartLayout.getChartAreaBoundingBox();
    return {
        x: {
            min: chartLayout.getHAxisValue(chartBounds.left),
            max: chartLayout.getHAxisValue(chartBounds.left + chartBounds.width)
        },
        y: {
            min: chartLayout.getVAxisValue(chartBounds.top + chartBounds.height),
            max: chartLayout.getVAxisValue(chartBounds.top)
        }
    };
}

function showSelection(row, column) {
    if(this.chart) {
        this.chart.setSelection([{row: row, column: column}]);
    };
}


const getOptions = header =>{
    header.shift();
    let opt = options;
    opt.colors = colors(header);
    return opt;
}

const options = {
    crosshair: {  orientation: 'vertical', trigger: 'selection' },
    vAxis: {
        minValue: 0,
        viewWindowMode: "explicit",
        viewWindow: {min: 0}},
    xAxis:{
      format: "date"
    },
    explorer: {
        actions: ["dragToZoom", "rightClickToReset"],
        axis: "horizontal",
        keepInBounds: true,
        maxZoomIn: 0.001
    },
    height: 300
}

const colors = header =>{
    let result = [];
    header.forEach(name =>{
        switch (name) {
            case _c.altitude:
                result.push("gray")
                break;
            case _c.power:
                result.push("#ce22f9");
                break;
            case _c.heart_rate:
                result.push("red");
                break;
            case _c.cadence:
                result.push("orange");
                break;
            case _c.speed:
                result.push("blue");
                break;
            case _c.left_pedal:
                result.push("#f8c4ff");
                break;
        }
    });
    return result;

}
