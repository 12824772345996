import React, {Component} from "react";
import Popup from "reactjs-popup";
import server from "../../http/server";
import {Table, Button} from 'react-bootstrap';
import "bootstrap/dist/css/bootstrap.css";
import {dispatchEvent, EVENTS, secToInterval} from "../utils";

export default class extends Component{

    constructor(props){
        super(props);
        this.state = {open: false};
        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.getProperty = getProperty.bind(this);
        this.load = load.bind(this);
    }

    openModal (){
        this.setState({ open: true });
        this.load();
    }
    closeModal () {
        this.setState({ open: false });
    }



    render(){
        return  (
            <>
                <Button variant="link" type="submit" onClick={this.openModal}>summary</Button>
                <Popup
                    position="right center"
                    contentStyle={{width: "40vw"}}
                    open={this.state.open}
                    closeOnDocumentClick
                    onClose={this.closeModal}
                >
                    <Table responsive size="sm">
                        <thead>
                        <tr>
                            <th className="summary-title">Duration</th>
                            <th className="summary-title">Distance</th>
                            <th className="summary-title">TSS</th>

                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>{displayTime(this.getProperty("total_timer_time"))}</td>
                            <td>{distance(this.getProperty("total_distance"))} km</td>
                            <td>{this.getProperty("training_stress_score")}</td>
                        </tr>
                        </tbody>
                    </Table>
                    <Table  responsive size="sm">

                        <tbody>
                        <tr>
                            <td className="summary-title">Work</td>
                            <td>{work(this.getProperty("total_work"))} kJ</td>
                            <td className="summary-title">IF</td>
                            <td>{this.getProperty("intensity_factor")}</td>
                        </tr>
                        <tr>
                            <td className="summary-title">NP</td>
                            <td>{this.getProperty("normalized_power")} W</td>
                            <td className="summary-title">VAM</td>
                            <td>{this.getProperty("avg_vam")}</td>
                        </tr>
                        <tr>
                            <td className="summary-title">El.Gain</td>
                            <td>{this.getProperty("total_ascent")} m</td>
                            <td className="summary-title">El.Loss</td>
                            <td>{this.getProperty("total_descent")} m</td>
                        </tr>
                         <tr>
                            <td className="summary-title">Cal</td>
                            <td>{this.getProperty("total_calories")}</td>
                            <td className="summary-title">ATE</td>
                            <td>{this.getProperty("total_anaerobic_training_effect")}</td>
                        </tr>
                        </tbody>
                    </Table>
                    <Table  responsive size="sm">
                        <thead>
                        <tr>
                            <th></th>
                            <th className="summary-title">AVG</th>
                            <th className="summary-title">MAX</th>

                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td className="summary-title">Power</td>
                            <td>{this.getProperty("avg_power")}</td>
                            <td>{this.getProperty("max_power")}</td>
                            <th className="summary-unit">w</th>
                        </tr>
                        <tr>
                            <td className="summary-title">Heart Rate</td>
                            <td>{this.getProperty("avg_heart_rate")}</td>
                            <td>{this.getProperty("max_heart_rate")}</td>
                            <th className="summary-unit">bpm</th>
                        </tr>
                        <tr>
                            <td className="summary-title">Cadence</td>
                            <td>{this.getProperty("avg_cadence")}</td>
                            <td>{this.getProperty("max_cadence")}</td>
                            <th className="summary-unit">rpm</th>
                        </tr>
                        <tr>
                            <td className="summary-title">Speed</td>
                            <td>{speed(this.getProperty("avg_speed"))}</td>
                            <td>{speed(this.getProperty("max_speed"))}</td>
                            <th className="summary-unit">km/h</th>
                        </tr>
                        <tr>
                            <td className="summary-title">Temp</td>
                            <td>{this.getProperty("avg_temperature")}</td>
                            <td>{this.getProperty("max_temperature")}</td>
                            <th className="summary-unit">&#x2103;</th>
                        </tr>
                        </tbody>
                    </Table>
                </Popup>
            </>
        )
    };
}

const work = v => {
    return Math.round(v / 1000);
}

const distance = v =>{
    return Math.round(v / 100) / 10;
}

const speed = v =>{
    let km_h = v *  3.6;
    return Math.round(km_h * 10) / 10;
}

function getProperty(name) {
    if(this.state.data){
        return this.state.data[name];
    }
    return null;
}

const displayTime = time =>{
    if(time){
        time = Math.round(time);
        return secToInterval(time);
    }
    return "";
}

async function load() {
    let result = await server.getTrackSummary(this.props.trackId, (e)=>{console.log("get summary: ", e)});
    this.setState({data: result})
}