import React, {Component} from "react";
import {Modal, Button} from 'react-bootstrap';
import {listen, stopListen, EVENTS} from "../utils";

const DIALOG = "dialog";

export const dialogData = (header, text, textClass, onConfirm ) => {
    let dialog = {
        header: header,
        text: text
    }
    if(textClass){
        dialog.textClass = textClass;
    }
    if(onConfirm){
        this.onConfirm = onConfirm;
    }
    return dialog;
}

export default class extends Component{

    constructor(props){
        super(props);
        this.state = {};


        this.onDialog = onDialog.bind(this);
        this.onHide = onHide.bind(this);
        this.confirmButton = confirmButton.bind(this);

        listen(EVENTS.dialog, this.onDialog);

    }

    render(){
        const data = this.state[DIALOG];
        if(data) {
            return <Modal
                show={data != null} onHide={this.onHide}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {data.header}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p className={data.textClass}>
                        {data.text}
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    {this.confirmButton(data)}
                    <Button onClick={this.onHide}>Close</Button>
                </Modal.Footer>
            </Modal>
        }else{
            return <></>
        }
    }

    componentWillUnmount(){
        stopListen(EVENTS.dialog_alert, onDialog);
    }
}

function confirmButton(config) {
    if(config.onConfirm){
        return <Button onClick={config.onConfirm}>Close</Button>
    }
}

function onDialog(data) {
    this.setState({[DIALOG]: data.detail});
}

function onHide() {
    this.setState({[DIALOG]: null});
}