import React, {Component} from "react";
import {Form, Button} from 'react-bootstrap';
import server from "../http/server";
import {errorMessage} from "./utils";

export default class extends Component{

    constructor(props){
        super(props);
        this.state = {};
        this.login = login.bind(this);
    }

    render(){
        return (
            <div className="login">
                <div className="login-content">
                    <Form>
                        <Form.Group controlId="formGroupUsername">
                        <Form.Label>Username</Form.Label>
                        <Form.Control type="username" placeholder="Enter username" onChange={e=>{
                            this.setState({username: e.target.text});
                        }}/>
                        </Form.Group>
                        <Form.Group controlId="formGroupPassword">
                            <Form.Label>Password</Form.Label>
                            <Form.Control type="password" placeholder="Password" onChange={e=>{
                                this.setState({password: e.target.text});
                            }}/>
                        </Form.Group>

                    </Form>
                    <Button variant="primary" type="submit" onClick={this.login}>
                        Login
                    </Button>
                </div>
            </div>);
    }
}

async function login() {
    let token = await server.login(this.state, errorMessage).catch(e=>{console.log("login error: ", e)});
}