import React, {Component} from "react";
import {Table, Button} from 'react-bootstrap';
import m_e, {events} from "../../events/events";
import server from "../../http/server";
import {Collapse} from 'react-collapse';
import Map from "./map";
import GuageCharts from "./gaugeCharts";


export default class extends Component{
    constructor(props){
        super(props);
        this.uploader = props.uploader;
        this.f_deleteFile = props.deleteFile;
        this.f_proccessFile = props.proccessFile;

        this.showFiles = showFiles.bind(this);
        this.tableRows = tableRows.bind(this);
        this.sessionRequestComplete = sessionRequestComplete.bind(this);
        this.refreshFiles = refreshFiles.bind(this);

        this.state = {};

        if(this.uploader) {
            this.uploader.on('onSessionRequestComplete', this.sessionRequestComplete);
            this.uploader.on("onComplete", (id, name, json) => {
                //console.log("onComplete: ", id, name, json)
                //this.sessionRequestComplete(json.files);
                if(json.files.length > 0){
                    json.files.forEach(f => {
                        if(!f.type) {
                            this.f_proccessFile(f.id);
                        }
                    });
                }
            });
        }else{
            this.refreshFiles(props.sid);
        }

        m_e.getInstance().on(events.file_deleted, async data => {
           this.refreshFiles(props.sid);
        });
        m_e.getInstance().on(events.file_proccessed, async data => {
            this.refreshFiles(props.sid);
        });
    }

    render(){

        return this.showFiles();
    }
}


async function refreshFiles(sid) {
    let files = await server.getFiles(sid, (e)=>{console.log("get files: ", e)});
    files = addMapRows(files);
    this.sessionRequestComplete(files);
}

const addMapRows = rows => {
    let result = [];
    rows.forEach(row => {
        result.push(row);
        result.push({id: row.id});
    });
    return result;
}

function sessionRequestComplete(data) {
    if(data ){
        this.setState({files: data});
    }
}

function showFiles() {
    if(this.state.files) {
        return <Table striped bordered hover className="file-display">
            <thead>
            <tr>
                <th>#</th>
                <th>Name</th>
                <th>Session</th>

                <th>Date</th>
                <th></th>
            </tr>
            </thead>
            <tbody>
            {this.tableRows()}
            </tbody>
        </Table>;
    }else{
        return <></>
    }
}

function tableRows(){
    const data = this.state.files;
    return data.map((row, key) =>{

                if(row.name)
                    return <tr key={key}>
                        <td>{row.id}</td>
                        <td>{row.name}</td>
                        <td>{row.sname}</td>

                        <td>{new Date(row.sdate).toLocaleDateString("gb")}</td>
                        <td>
                            { (row.type) && <Button variant="link" size="sm" onClick={() => {this.setState({map: (this.state.map ? null : row.id)})}}>Map</Button>}
                            { (row.type) && <a href={"/analyse/" + row.id}>Analyse</a>}
                            { (!row.type) && <Button variant="link" size="sm" onClick={() => {this.f_proccessFile(row.id)}}>Proccess {row.type ? "(Yes)" : ""}</Button>}
                            <Button variant="link" size="sm" onClick={() => {this.f_deleteFile(row.session_id, row.uuid)}}>Delete</Button>

                        </td>
                    </tr>

                    return <tr key={key}>
                        <td colSpan="5" className="map-td">
                            <Collapse isOpened={this.state.map == row.id}>
                                <div className="track-map">
                                    {(this.state.map == row.id) && <Map divId={"map-" + row.id} source={row.id}/>}
                                    {(this.state.map == row.id) && <GuageCharts mapId={row.id}/>}
                                </div>
                            </Collapse>
                        </td>
                    </tr>
             }

    )
}