import server from "../http/server";
import {errorMessage} from "../pwcompare/utils";

export const _c = {
    position_long: "position_long",
    position_lat: "position_lat",
    timestamp: "timestamp",
    altitude: "altitude",
    power: "power",
    heart_rate: "heart_rate",
    cadence: "cadence",
    speed: "speed",
    distance: "distance",
    left_pedal: "left_pedal"
}

export const TRACK_DATA_FILTERS = {
    GEO_JSON: [_c.position_long, _c.position_lat],
    ANALYSE: [_c.timestamp, _c.left_pedal, _c.altitude, _c.speed, _c.cadence, _c.heart_rate, _c.power],
    ALTITUDE_GRAPH: [_c.timestamp, _c.altitude],
    ANIMATION_FIELDS: [_c.timestamp, _c.position_long, _c.position_lat, _c.speed, _c.power, _c.heart_rate, _c.cadence, _c.altitude]
};

const LAP_DATA = ["timestamp", "total_elapsed_time", "avg_power", "avg_heart_rate", "avg_cadence"];

const display = name =>{
     switch (name) {
        case _c.position_long:
            return "Longtitude";
        case _c.position_lat:
            return "Latitude";
        case _c.timestamp:
            return "Time";
        case _c.altitude:
            return "Altitude";
        case _c.power:
            return "Power";
        case _c.heart_rate:
            return "Heart Rate";
        case _c.cadence:
            return "Cadence";
        case _c.speed:
            return "Speed";
        case _c.distance:
            return "Distance";
        case _c.left_power:
            return "Left Power";
         default:
             return name;
    }
}



export default (function () {
    let _instance;
    let m_tracks = {};

    async function loadTrack(trackId, reload) {
        let trackData = m_tracks[trackId];
        if(!trackData || reload){
            trackData = await loadTrackData(trackId);
            m_tracks[trackId] = trackData;
        }
        return trackData;
    }


    function init() {
        return {
            getTrackGeoJson: async (trackId, reload)=>{
                let trackData = await loadTrack(trackId, reload);
                let geoTrackData = new TrackData(TRACK_DATA_FILTERS.GEO_JSON, trackData).filetered();
                return {features: [
                    {geometry: {
                        coordinates: geoTrackData,
                        type:"LineString"
                    },
                        type:"Feature"
                    }
                ],
                    crs:{
                        type:"name",
                            properties:{
                                name:"EPSG:4326"
                            }
                    },
                    type:"FeatureCollection"
                };
            },
            getGeoCoordinates: async (trackId, reload)=>{
                let trackData = await loadTrack(trackId, reload);
                return new TrackData(TRACK_DATA_FILTERS.GEO_JSON, trackData).filetered();
            },

            getAltitudeGraph: async (trackId, reload)=>{
                let trackData = await loadTrack(trackId, reload);
                return  new TrackData(TRACK_DATA_FILTERS.ALTITUDE_GRAPH, trackData).filetered();
            },

            getAnimateFields: async (trackId, reload)=>{
                let trackData = await loadTrack(trackId, reload);
                return  new TrackData(TRACK_DATA_FILTERS.ANIMATION_FIELDS, trackData, true).filetered();
            },

            getAnalyseChart: async (trackId, reload)=>{
                let trackData = await loadTrack(trackId, reload);
                return  new TrackData(TRACK_DATA_FILTERS.ANALYSE, trackData).fileteredWithHeader();
            },

            getLaps: async (trackId, reload)=>{
                let trackData = await loadTrackLaps(trackId);
                return  new TrackData(LAP_DATA, trackData, true).filetered(true);
            },

            getAnalyseColors: () =>{
                return ["#ce22f9", "red",  "orange", "blue", "gray", "#f1c4fc", "#f8c4ff"];
            },

            loadTrack: async (trackId, reload)=> {
                return loadTrack(trackId, reload);
            }
        };

    };

    return {
        instance: function () {
            if ( !_instance ) {
                _instance = init();
            }
            return _instance;
        }

    };

})();

async function loadTrackData(trackId) {
    return await server.getTrackDataJson(trackId, error=> errorMessage(error.message));
}

async function loadTrackLaps(trackId) {
    return await server.getTrackLaps(trackId, error=> errorMessage(error.message));
}


export class TrackData{
    constructor(filter, data, zeroOnMissingData){
        this.filter = [...filter];
        this.data = data;
        this.zeroOnMissingData = zeroOnMissingData;
    }

    fileteredWithHeader(){
        let result = this.filetered();
        result.unshift(this.filter);
        return result;
    }

    filetered(avoidTimeFormating){
        let mask = [];
        let data = [...this.data];
        let headerRow = data.shift();
        let newFilter = [];

        for(let i = 0; i < this.filter.length; i++){
            let index = headerRow.indexOf(this.filter[i]);
            if(!this.zeroOnMissingData) {
                if (index !== -1) {
                    mask.push(index);
                    newFilter.push(this.filter[i]);
                }
            }else{
                mask.push(index);
            }
        }

        this.filter = newFilter;
        let result = [];

        let timeIndex = avoidTimeFormating ? -1 : this.filter.indexOf(_c.timestamp);

        data.forEach(row=>{
            let newRow = [];
            mask.forEach((index, i) => {
                if(i === timeIndex){
                    newRow.push(new Date(row[index]*100 ));
                }else {
                    newRow.push(row[index]);
                }
            });
            result.push(newRow);
        });
        return result;
    }
}


