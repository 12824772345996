import React, {Component} from "react";
import DatePicker from "react-datepicker";
import {dispatchEvent, EVENTS} from "./../utils";

import "react-datepicker/dist/react-datepicker.css";

export default class extends Component{
    constructor(props) {
        super(props);
        this.state = {
            startDate: new Date()
        };
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(date) {
        this.setState({
            startDate: date
        });
        dispatchEvent(EVENTS.date_selected, date);
    }

    render() {
        return (
            <DatePicker
                selected={this.state.startDate}
                onChange={this.handleChange}
                className="picker-text"
            />
        );
    }
}