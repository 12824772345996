import React, { Component } from 'react';
import {listen, dispatchEvent, EVENTS, calcAvg, millsToInterval, caclLR, round, hideLoading} from "./utils";
import TrackLoader, {_c} from "../model/trackLoader";
import {Table} from 'react-bootstrap';

export default class extends Component{



    constructor(props){
        super(props);
        this.state = {};
        this.calcAvgs = calcAvgs.bind(this);

        listen(EVENTS.chartSelection(this.props.source), (data)=> {
            this.calcAvgs(data.detail);

        });
    }

    newState = function (e) {
        this.setState(e.detail);
    }

    render(){
        if(this.state.speed) {
            return (
                <div className="result">
                    <Table striped bordered hover size="sm">
                        <thead>
                        <tr>
                            <th colSpan="2">Data</th>
                        </tr>
                        </thead>
                        <tbody>
                        {this.state.power && <tr>
                            <td>Power(watts)</td>
                            <td>{this.state.power}</td>

                        </tr> }
                        {this.state.left_pedal && <tr>
                            <td>Left(watts)</td>
                            <td>{this.state.left_pedal}</td>

                        </tr>}
                        {this.state.left && this.state.left !== "N/A" &&<tr>
                            <td>L/R</td>
                            <td >{this.state.left}% / {this.state.right}%</td>
                        </tr>}
                        <tr>
                            <td>Heart Rate(bpm)</td>
                            <td>{this.state.heart_rate}</td>

                        </tr>
                        <tr>
                            <td>Cadence(rpm)</td>
                            <td>{this.state.cadence}</td>

                        </tr>
                        <tr>
                            <td>Speed(km/h)</td>
                            <td>{this.state.speed}</td>

                        </tr>
                        <tr>
                            <td>Altitude(m)</td>
                            <td>{this.state.altitude}</td>

                        </tr>
                        <tr>
                            <td>Time</td>
                            <td>{this.state.time}</td>

                        </tr>
                        </tbody>
                    </Table>
                </div>
            );
        }else{
            return <></>
        }
    };
}

async function calcAvgs(selection) {
    let data = await TrackLoader.instance().getAnalyseChart(selection.trackId);
    let header = data.shift();
    data = data.slice(selection.min, selection.max);
    let result = {};
    data.forEach(row=>{
        header.forEach((f, i) => {
            if(!result[f]){
                result[f] = row[i];
            }else{
                result[f] += row[i];
            }
        });
    });

    header.forEach((f, i) => {
        if(_c.speed === f){
            result[f] = round(result[f] / data.length);
        }else {
            result[f] = calcAvg(result[f], data.length);
        }
    });

    let timeIndex = header.indexOf(_c.timestamp);
    result.time = millsToInterval(data[data.length -1][timeIndex] - data[0][timeIndex]);

    let left_right = caclLR(result.power, result.left_pedal);
    result.left = left_right.left;
    result.right = left_right.right;

    this.setState(result);
    hideLoading("selection-result");

}