import config from "../../../config/config.json";
import axios from "axios";
import {showLoading, hideLoading, dispatchEvent, EVENTS} from "../pwcompare/utils"

export default {
    login: async function (params, onError) {
        showLoading("login");
        let error = {error: "Server error"};
        let data = await axios.post(config.service.url + "/login", params).catch(e=>{
            error = {error: e.message}
        });
        hideLoading("login");
        if(!data){
            data = error;
        }else{
            data = data.data;
        }
        console.log("data: ", data);


        if(validate(data)) {
            return data.token;
        }
        onError(data.error);
    },
    createUploadSession: async function (params, onError) {
        showLoading("createUploadSession");
        let data = (await axios.post(config.service.url + "/session", params)).data;
        hideLoading("createUploadSession");
        if(validate(data)) {
            return data.id;
            //return JSON.parse(data).id;
        }
        onError(data.error);
    },
    loadUploadSessions: async function(params, onError) {
        showLoading();
        let data = (await axios.get(config.service.url + "/sessions", params)).data;
        hideLoading("showLoading");
        if (validate(data)) {
            return data;
        }
        onError(data.error);
    },
    deleteSessions: async function(sessionId, onError){
        showLoading();
        let data = (await axios.delete(config.service.url + "/session/" + sessionId)).data;
        showLoading("deleteSessions");
        if (validate(data)) {
            return "deleted: " + sessionId;
        }
        onError(data.error);
    },
    deleteTrack: async function(trackId, onError){
        showLoading("delete track");
        let data = (await axios.delete(config.service.url + "/track/" + trackId)).data;
        hideLoading("delete track");
        if (validate(data)) {
            return data;
        }
        onError(data.error);
    },
    deleteFile: async function(sessionId, uuid, onError){
        showLoading();
        let data = (await axios.delete(config.service.url + "/file/" + sessionId + "/" + uuid)).data;
        hideLoading("deleteFile");
        if (validate(data)) {
            dispatchEvent(EVENTS.file_deleted, {sid: sessionId, uuid: uuid});
            return "deleted: " + sessionId + " > " + uuid;
        }
        onError(data.error);
    },
    proccessFile: async function(fileId, onError){
        showLoading();
        let data = (await axios.put(config.service.url + "/file/" + fileId)).data;
        hideLoading("proccessFile");
        if (validate(data)) {
            dispatchEvent(EVENTS.file_proccessed, {id: fileId});
            return "proccessed: " + fileId;
        }
        onError(data.error);
    },
    getTrack: async function(fileId, onError){
        showLoading();
        let data = (await axios.get(config.service.url + "/track/" + fileId)).data;
       // hideLoading("getTrack");
        if (validate(data)) {
            return data;
        }
        onError(data.error);
    },
    getTrackSummary: async function(trackId, onError){
        showLoading("getTrackSummary");
        let data = (await axios.get(config.service.url + "/summary/" + trackId)).data;
        hideLoading("getTrackSummary");
        if (validate(data)) {
            return data;
        }
        onError(data.error);
    },
    editTrack: async function(trackId, params, onError){
        showLoading("edit track");
        let data = (await axios.post(config.service.url + "/track/" + trackId, params)).data;
         hideLoading("edit track");
        if (validate(data)) {
            return data;
        }
        onError(data.error);
    },
    getGeoJson: async function(fileId, onError){
        let data = (await axios.get(config.service.url + "/geojson/" + fileId)).data;

        if (validate(data)) {
            return data;
        }
        onError(data.error);
    },
    getTrackDataJson: async function(fileId, onError){
        let data = (await axios.get(config.service.url + "/data/" + fileId)).data;

        if (validate(data)) {
            return data;
        }
        onError(data.error);
    },
    getTrackLaps: async function(fileId, onError){
        let data = (await axios.get(config.service.url + "/laps/" + fileId)).data;

        if (validate(data)) {
            return data;
        }
        onError(data.error);
    },
    getFiles: async function(sessionId, onError){
        showLoading("getFiles");
        let data = (await axios.get(config.service.url + "/files/" + sessionId));
        hideLoading("getFiles");
        if (validate(data)) {
            return data.data;
        }
        onError(data.error);
    },
    getDateFiles: async function(timestamp, onError){
        showLoading("getDateFiles");
        let data = (await axios.get(config.service.url + "/datefiles/" + timestamp));
        hideLoading("getDateFiles");
        if (validate(data)) {
            return data.data;
        }
        onError(data.error);
    }
}

function validate(data) {
    return !(data.error);
}