import React, {Component} from "react";
import FineUploaderTraditional from "fine-uploader-wrappers";
import Gallery from "react-fine-uploader";
import "react-fine-uploader/gallery/gallery.css";
import {Alert} from 'react-bootstrap';
import {errorAlert} from "./utils";
import server from "../http/server";
import FileDisplay from "./c/fileDisplay";
import _conf from "../../../config/config.json";
import session from "../model/user"


const options = session => {
    return {
        options: {
            chunking: {
                enabled: false
            },
            deleteFile: {
                enabled: true,
                forceConfirm: true,
                endpoint: _conf.service.url + "/file/" + session
            },
            request: {
                endpoint: _conf.service.url + "/upload/" + session
            },
            retry: {
                enableAuto: false
            }
        }
    };
}

export default class Upload extends Component{
    constructor(props){
        super(props);
        this.state = {loading: false};
        this.createSession = createSession.bind(this);
        this.alert = alert.bind(this);
        this.showGallery = showGallery.bind(this);
        this.deleteFile = deleteFile.bind(this);
        this.proccessFile = proccessFile.bind(this);
        this.reload = reload.bind(this);
    }

    render(){
        return (
            <div className="upload-screen">
                {this.createSession()}
            </div>
        );
    }
}

function createSession() {
        return  <div className="right-div">
            {this.alert()}
            <div >
                {this.showGallery()}
            </div>
        </div>

}

class GaleryTab extends Component{
    constructor(props){
        super(props);
        this.uploader = new FineUploaderTraditional(props.options);
        this.deleteFile = props.deleteFile;
        this.proccessFile = props.proccessFile;
        this.parent = props.parent;



    }

    render(){
            return <>
                <Gallery uploader={ this.uploader } />
                <FileDisplay uploader={this.uploader} deleteFile={this.deleteFile} proccessFile={this.proccessFile} sid={session.user().userid} />
            </>

    }
}

function showGallery() {
    console.log("createSession:", session.user().userid)
    if(session.user().userid){
        return  <div >
                <GaleryTab parent={this} deleteFile={this.deleteFile} proccessFile={this.proccessFile} options={options(session.user().userid)} />
            </div>
    }else{
        return <></>
    }
}

function alert() {
    if(this.state.alert) {
        return <div className="alert-wrapper">
            <Alert className="alertc" variant={this.state.alert.style}>
                {this.state.alert.msg}
            </Alert>
        </div>
    }
}

function deleteFile(sessionId, uuid) {
    (async ()=>{
        await server.deleteFile(sessionId, uuid,  (error)=>{this.parent.setState({alert: errorAlert(error)})});
        this.reload();
    })();
}

function proccessFile(fileId) {
    (async ()=>{
        await server.proccessFile(fileId, (error)=>{this.parent.setState({alert: errorAlert(error)})});
        this.reload();
    })();
}

function reload() {
    this.setState({reload: new Date()});
}

