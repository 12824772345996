export default (function () {
    let _instance;
    
    function init() {

        let userid = 1;

        return {
            userid: userid
        };
    }
    
    function user() {
        if(!_instance){
            console.log("new user")
            _instance = init();
        }
        return _instance;
    }

    return {
        user: user
    }
})();