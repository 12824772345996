import $ from "jquery";
import m_e, {events} from "../events/events";
import {dialogData} from "./c/dialog";

export default function chartDataFix(dataArr) {
    for(let i =0; i < dataArr.length; i ++){
        dataArr[i][0] = new Date(dataArr[i][0] * 100);
    }
    return dataArr;
}

export function calcAvg(sum, count){
    return Math.round(sum / count);
}

export  function caclLR(watts, left){
    var left = round((left / watts) * 100);
    var right;
    if( !isNaN(left)) {
        right = 100 - left;
    }else{
        left = "N/A";
        right = "N/A";
    }
    return {left: left, right: right};
}

function getSelectionIime(rangeRows, dataArray){
    var start = rangeRows[0] > 0 ? rangeRows[0] : 1;
    var startRow = dataArray[start];
    var endRow = dataArray[rangeRows[rangeRows.length - 1]];
    var sec = (endRow[0] / 1000) - (startRow[0] / 1000);


    var hour = ( Math.floor(sec / 3600));
    var minuites = ( Math.floor((sec % 3600) / 60));
    sec = sec % 60;

    return displeyTime(hour) + ":" + displeyTime(minuites) + ":" + displeyTime(sec);
}

export function millsToInterval(mills){
    var sec = mills / 1000;
    return secToInterval(sec);
}

export function secToInterval(sec) {
    var hour = ( Math.floor(sec / 3600));
    var minuites = ( Math.floor((sec % 3600) / 60));
    sec = sec % 60;

    return displeyTime(hour) + ":" + displeyTime(minuites) + ":" + displeyTime(sec);
}


function displeyTime(e){
    return (e > 9) ? e : ("0" + e);
}

export const round = num =>{
    return Math.round(num * 100) / 100;
}

export class Utils{
    getSelectionIime = getSelectionIime;
}

export function height() {
    return $(window).height();
}

export const errorAlert = error => {
    return {msg: error, style: "danger"};
}

export const okAlert = text => {
    return {msg: text, style: "info"};
}

export const showLoading = (sender) =>{
    if (sender) {console.log("show loading from: ", sender)};
    m_e.getInstance().dispatch(events.loading);
}

export const hideLoading = (sender) =>{
    if (sender) {console.log("hide loading from: ", sender)};
    m_e.getInstance().dispatch(events.loadingoff);
}

export const showDialog = data => {
    m_e.getInstance().dispatch(events.dialog, data);
}

export const errorMessage = message => {
    showDialog(dialogData("ERROR", message));
}

export const alert = (title, text) => {
    showDialog(dialogData(title, text));
}

export const confirm = (title, text, onConfirm) => {
    showDialog(dialogData(title, text, null,  onConfirm));
}

export const dispatchEvent = (event, data) =>{
    m_e.getInstance().dispatch(event, data);
}

export const listen = (event, onEvent) => {
    m_e.getInstance().on(event, onEvent);
}

export const stopListen = (event, onEvent) => {
    m_e.getInstance().off(event, onEvent);
}

export const commonTrack = (trrack1, track2, startPoint, endPoint) => {
    startPoint = {"type":"Point","coordinates":startPoint};
    endPoint = {"type":"Point","coordinates":endPoint};
    let result = [];
}

export const isOkResponse = res =>{
    return res && res.res === "OK";
}

export const EVENTS =  events;
