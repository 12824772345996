import React, {Component} from "react";
import Popup from "reactjs-popup";
import server from "../../http/server";
import {Form,Button} from 'react-bootstrap';
import "bootstrap/dist/css/bootstrap.css";
import {dispatchEvent, EVENTS} from "../utils";

export default class extends Component{

    constructor(props){
        super(props);
        this.state = {open: false};
        this.submit = submit.bind(this);
        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    openModal (){
        this.setState({ open: true });
    }
    closeModal () {
        this.setState({ open: false });
    }

    render(){
        return  (
            <>
                <Button variant="link" type="submit" onClick={this.openModal}>edit</Button>
                <Popup
                       position="right center"
                       contentStyle={{width: "40vw"}}
                       open={this.state.open}
                       closeOnDocumentClick
                       onClose={this.closeModal}
                >
                    <div className="popup-main">
                        <Form.Group controlId="formBasicEmail">
                            <Form.Label>Track name</Form.Label>
                            <Form.Control type="text" placeholder="Enter name" onChange={event => {this.setState({name: event.target.value})}}/>
                        </Form.Group>

                        <Form.Group controlId="exampleForm.ControlTextarea1">
                            <Form.Label>Track description</Form.Label>
                            <Form.Control as="textarea" rows="3" onChange={event => {this.setState({descr: event.target.value})}} />
                        </Form.Group>

                        <Button variant="info" type="submit" onClick={this.submit}>
                            Submit
                        </Button>
                    </div>
                </Popup>
            </>
        )
    };
}

async function submit() {
    let result = await server.editTrack(this.props.trackId, {name: this.state.name, descr: this.state.descr}, (e)=>{console.log("get files: ", e)});
    if(result){
        this.closeModal();
        dispatchEvent(EVENTS.reload_home)
    }
}