export default (function () {

    // Instance stores a reference to the Singleton
    let instance;

    function init() {

        // Singleton

        // Private methods and variables
        function privateMethod(){
            console.log( "I am private" );
        }

        let root = document.getElementById("root");

        return {

            // Public methods and variables
            dispatch: function (event, data) {
                root.dispatchEvent(new CustomEvent(event, {detail: data}));
            },
            on: function (event, handler) {
                root.addEventListener(event, handler);
            },
            off: function (event, handler) {
                root.removeEventListener(event, handler);
            }
        };

    };

    return {

        // Get the Singleton instance if one exists
        // or create one if it doesn't
        getInstance: function () {

            if ( !instance ) {
                instance = init();
            }

            return instance;
        }

    };

})();

export const events = {
    log: "log",
    loading: "loading:on",
    loadingoff: "loading:off",
    file_deleted: "file_deleted",
    file_proccessed: "file_proccessed",
    chart_ready: "chart_ready",
    dialog: "dalog",
    date_selected: "date-selected",
    reload_home: "reload-home",
    location: mapId => {return "location:" + mapId},
    powerHeartCadance: mapId => {return "powerHeartCadance:" + mapId},
    mapAnimationSpeed: mapId => {return "mapAnimationSpeed:" + mapId},
    visualHistory: mapId => {return "visualHistory:" + mapId},
    altitudeData: mapId => {return "altitudeData:" + mapId},
    lapSelection: mapId => {return "lapSelection:" + mapId},
    chartSelection: mapId => {return "chartSelection:" + mapId}
}
