import React, {Component} from "react";
import server from "../../http/server";
import {listen, EVENTS, isOkResponse} from "./../utils";
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { FaBicycle} from "react-icons/fa";
import EditPopup from "./editPopup";
import SummaryPopup from "./summaryPopup";
import {Button} from 'react-bootstrap';
import "bootstrap/dist/css/bootstrap.css";


export default class extends Component{

    constructor(props){
        super(props);
        this.state = {};
        listen(EVENTS.date_selected, (data => {
            this.loadFiles( data.detail.getTime());
        }));
        listen(EVENTS.reload_home, ()=>{
           this.loadFiles(this.state.time);
        });

        this.deleteTrack = deleteTrack.bind(this);
    }

    componentDidMount(){
        this.loadFiles(new Date().getTime());
    }

    async loadFiles(timestamp){
        let files = await server.getDateFiles(timestamp, (e)=>{console.log("get files: ", e)});
        this.setState({data: filesToEvents(files), time: timestamp});
    }

    render(){
        if(this.state.data) {
        return (
            <div className="catalog-xi2whi-AppLayout-AppLayout">
            <VerticalTimeline >
                {this.state.data.map((o) =>(
                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        date={o.ts}
                        iconStyle={{background: 'rgb(33, 150, 243)', color: '#fff'}}
                        key={o.id}
                        icon={<FaBicycle />}
                    >

                        <h4 className="vertical-timeline-element-subtitle">{o.sname}</h4>
                        <p>{o.descr}</p>
                        <p>{o.file + "(" + o.id + ")"}</p>
                        <div className="calendar-links">
                            <a href={"/analyse/" + o.fid} style={{}}>open</a>
                            <EditPopup trackId={o.id}/>
                            <SummaryPopup trackId={o.id}/>
                            <Button variant="link" type="submit" onClick={()=>{
                                this.deleteTrack(o.id);
                            }} style={{'margin-left': '50%'}}>del</Button>
                        </div>
                    </VerticalTimelineElement>
                ))}


            </VerticalTimeline>
            </div>
        )
        }else{
            return <></>
        }
    }
}


const filesToEvents = filesArr =>{
    let events = [];
    filesArr.forEach(f => {
        let start = new Date(f.time ? f.time : f.sdate);

        events.push({ts: start.toLocaleString(), file: f.fname, id:f.id, sname: f.track_name, descr: f.track_descr, fid: f.file_id})
    });
    return events;
}

async function deleteTrack(trackId) {
    let result = await server.deleteTrack(trackId, (e)=>{console.log("DELETE TRACK: ", e)});
    if(isOkResponse(result)){
        this.loadFiles(this.state.time);
    }else{
        console.log("delte track problem: ", result);
    }
}