import React, {Component} from "react";
import "../../../css/main.scss";
import {BrowserRouter as Router, Route, Link} from "react-router-dom";
import Login from "./login";
import Tools from "./tool-page";
import Home from "./home.js";
import Upload from "./upload";
import "bootstrap/dist/css/bootstrap.css";
import Loading from "./c/loading";
import Dialog from "./c/dialog";
import DatePicker from "./c/datePicker";

export default class Main extends Component{

    constructor(props){
        super(props)
    }

    render(){
        return (

            <Router
            >
                <div className="main">


                    <div className="content">
                        <Route exact path="/login" component={Login}/>
                        <Route exact path="/tools/:mapId" component={Tools}/>

                        <Route exact path="/" render={() => {return menu(true)}}/>
                        <Route exact path="/" component={Home}/>

                        <Route exact path="/analyse/:fileId" render={() => {return menu(false)}}/>
                        <Route exact path="/analyse/:mapId" component={Tools}/>

                        <Route exact path="/upload" render={() => {return menu(false)}}/>
                        <Route exact path="/upload" component={Upload}/>
                    </div>
                    <Loading/>
                    <Dialog/>
                </div>
            </Router>
        );
    };
}

const menu = (showDatePicker)=>{
    return <div className="menu">
        <div className="menu-item">
        <Link to="/">Home</Link>
        </div>
        <div className="menu-item">
        <Link to="/upload/">Upload</Link>
        </div>
        {showDatePicker && <div className="date-picker"><DatePicker/></div>}
        </div>
}

