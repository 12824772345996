import React, {Component} from 'react';
import 'ol/ol.css';
import Map from 'ol/Map';
import View from 'ol/View';
import {Vector as VectorLayer} from 'ol/layer';
import {Vector as VectorSource} from 'ol/source';
import Collection from 'ol/Collection';
import Point from 'ol/geom/Point';
import LineString from 'ol/geom/LineString';
import Feature from 'ol/Feature';
import {Stroke, Style, Icon, Text, Fill} from "ol/style";
import TileLayer from 'ol/layer/Tile';
import Osmap from 'ol/source/XYZ';
import _config from "../../../../config/config.json"
import {listen, dispatchEvent, EVENTS} from "../utils";
import config from "../../../../config/config.json";
import {defaults as defaultControls, FullScreen} from "ol/control";
import TrackLoader from "../../model/trackLoader";


const  source = new Osmap({
    url: 'http://osmap.org/tile/{z}/{x}/{y}.png'
});

const styles = {
    "LineString": new Style({
        stroke: new Stroke({
            color: 'red',
            width: 2
        })
    }),
    "Point": new Style({
        //text: new Text(baseTextStyle),
        image: new Icon({
            src: "http://osmap.org/img/icons/here.png"
        }),
        zIndex: 2
    }),
    'Circle': new Style({
        stroke: new Stroke({
            color: 'red',
            width: 2
        }),
        fill: new Fill({
            color: 'rgba(255,0,0,0.2)'
        })
    })
}


const styleFunction = function(feature) {
    return styles[feature.getGeometry().getType()];
};

const selectedTrack = function () {
    return new Style({
        stroke: new Stroke({
            color: 'yellow',
            width: 7
        })
    });
}

export default class extends Component {

    constructor(props){
        super(props);
        this.divId = "map_" + props.source;
        this.source = props.source;
        this.trackLoader = TrackLoader.instance();
        this.map = null;

        this.addPoint = addPoint.bind(this);
        this.addLine = addLine.bind(this);
        this.center = center.bind(this);
        this.fitTrackDisplay = fitTrackDisplay.bind(this);
        this.startVisualization = startVisualization.bind(this);
        this.displayTrack = displayTrack.bind(this);
        this.newTrack = newTrack.bind(this);
        this.trackVectorSource = null;
        this.pointVectorSource = null;
        this.nav = null;
        this.layers = {};


        listen(EVENTS.location(this.source), newLocation.bind(this));
        listen(EVENTS.mapAnimationSpeed(this.source), (data)=>{
            this.nav.setSpeed(data.detail);
        });

        listen(EVENTS.visualHistory(this.source), (data)=> {
            if (this.nav) {
                this.nav.setValue(data.detail);
            }
        });
        listen(EVENTS.chartSelection(this.source), (data)=> {
            this.newTrack(data.detail);

        });
    }



    componentDidMount() {
        (async () => {

            this.trackVectorSource = new VectorSource({
                features: new Collection(),
                useSpatialIndex: true // optional, might improve performance
            });

            this.pointVectorSource = new VectorSource({
                features: new Collection(),
                useSpatialIndex: false // optional, might improve performance
            });

            this.map = new Map({
                target: this.divId,
                // controls: defaultControls().extend([
                //     new FullScreen()
                // ]),
                layers: [
                    new TileLayer({source: source}),
                    new VectorLayer({
                        source: this.trackVectorSource,
                        style: styleFunction,
                        updateWhileAnimating: true, // optional, for instant visual feedback
                        updateWhileInteracting: true // optional, for instant visual feedback
                    }),
                    new VectorLayer({
                        source: this.pointVectorSource,
                        style: styleFunction,
                        updateWhileAnimating: true, // optional, for instant visual feedback
                        updateWhileInteracting: true // optional, for instant visual feedback
                    })
                ],
            });

            this.map.setView(
                new View({
                    //projection: 'EPSG:4326',
                    projection: "EPSG:3857",
                    minZoom: 6,
                    maxZoom: 19,
                    center: _config.map.center,
                    zoom: _config.map.zoom,
                    rotation: _config.map.rotation
                })
            );
           await this.displayTrack();
           await this.startVisualization();

        })();
    }

    render() {

        return (
            <div className="map-holder">
                <div id={this.divId} className="map"></div>
            </div>
        );
    }
}

async function newTrack(selection) {
    let coords = await this.trackLoader.getGeoCoordinates(selection.trackId);

    if(selection.min === 0 && (selection.max + 1) === coords.length){
        if(this.layers[selection.trackId]){
            this.map.removeLayer(this.layers[selection.trackId]);
            this.layers[selection.trackId] = null;
        }
        this.fitTrackDisplay(this.trackVectorSource);
    }else {
        coords = coords.slice(selection.min, selection.max);

        if (!this.layers[selection.trackId]) {
            let layer = createNewTracklayer(coords);
            //this.map.addLayer(layer);
            addLayer(layer, this.map);
            this.layers[selection.trackId] = layer;
        }
        addLineToVectorSource(coords, this.layers[selection.trackId].getSource());
        this.fitTrackDisplay(this.layers[selection.trackId].getSource());
    }
}

function addLayer(layer, map){
    let ls = map.getLayers();
    if(ls.getLength() > 1){
        ls.insertAt(ls.getLength() - 2, layer);
    }else{
        map.addLayer(layer);
    }
}

function createNewTracklayer(coords) {

    let source = new VectorSource({
        features: new Collection(),
        useSpatialIndex: true
    });

    return  new VectorLayer({
        source: source,
        style: selectedTrack,
        updateWhileAnimating: true, // optional, for instant visual feedback
        updateWhileInteracting: true // optional, for instant visual feedback
    });
}

async function displayTrack() {
    let trackData = await this.trackLoader.getGeoCoordinates(this.source);
    this.addLine(trackData);
    this.fitTrackDisplay(this.trackVectorSource);
}

function fitTrackDisplay(source) {
    this.map.getView().fit(source.getExtent(), this.map.getSize());
    let zoom = this.map.getView().getZoom();
    let center = this.map.getView().getCenter();

    let view =  new View({
        //projection: 'EPSG:4326',
        projection: "EPSG:3857",
        minZoom: 6,
        maxZoom: 19,
        center: center,
        zoom: zoom ? zoom : _config.map.zoom,
        rotation: _config.map.rotation
    });

    this.map.setView(view);
}

class Navigator{
    constructor(data, source){
        this.data = data;
        this.source = source;
        this.index = 0;
        this.speed = 0;
        this.end = false;
        this.animate();

    }

    async animate(){
        let i = 0;
        while (!this.end) {
            await new Promise((resolve, reject) => {
                setTimeout(() => {
                    let nextIndex = this.getNext();

                    if (nextIndex && (nextIndex !== this.index)) {
                        this.index = nextIndex;
                        this.showPoint();
                    }
                    resolve();
                }, this.animationInterval())
            });
        }
    }

    animationInterval(){
        const range = 1000;
        return  (range - (range * (this.speed /100)));;
    }

    getNext(){
        if(this.speed > 0){
            let step = this.index + 1;
            return step > this.data.length - 1 ? 0 : step;
        }
        return this.index;
    }

    showPoint(){
        let dataArr = this.data[this.index];
        let coords = getPointCoords(dataArr);
        let powerHeartCadanceSpeed = getPowerHeartCadeneSpeed(dataArr);
        dispatchEvent(EVENTS.location(this.source), {point: coords, data: powerHeartCadanceSpeed});
        dispatchEvent(EVENTS.powerHeartCadance(this.source), {
            data: powerHeartCadanceSpeed,
            speed: 10,
            history: Math.round(this.index * 100 / this.data.length),
            altIndex: this.index
        });
    }

    setSpeed(percent){
        this.speed = percent;
    }

    setValue(percent){
        this.index = this.indexByPercent(percent);
        this.showPoint();
    }

    indexByPercent(percent){
        return Math.round(this.data.length * (percent / 100));

    }
}

async function startVisualization() {
    let altitudeData = getAltitudeData(await this.trackLoader.getAltitudeGraph(this.source));
    dispatchEvent(EVENTS.altitudeData(this.source), altitudeData);

    let animationData = await this.trackLoader.getAnimateFields(this.source);
    this.nav = new Navigator(animationData, this.source);
    this.nav.showPoint();
}

function getPointCoords(dataArr) {
    return [dataArr[1], dataArr[2]];
}

function getPowerHeartCadeneSpeed(dataArr) {
    return [dataArr[4], dataArr[5], dataArr[6], dataArr[3]];
}

function getAltitudeData(dataArr){
    let min = 8000;
    dataArr.forEach(row=>{
        row[1] = Math.round(row[1])
        min = min < row[1] ? min : row[1];
    });
    return {result: dataArr, min: min};
}

function addPoint(point, name, icon){
    this.pointVectorSource.getFeaturesCollection().clear();

    let textFeature = new Feature({
        geometry: new Point(point),
        text: name ? formatText(name) : "",
        icon: icon ? icon : 'http://osmap.org/img/icons/here.png'
    });
    this.pointVectorSource.addFeature(textFeature);
    this.center(point);
}

function addLine(coords){
    addLineToVectorSource(coords, this.trackVectorSource);
}
function addLineToVectorSource(coords, source){
    source.getFeaturesCollection().clear();
    let track = new Feature({
        geometry: new LineString(coords)
    });
    source.addFeature(track);
}

const formatText = text =>{
    let baseTextStyle = {
        font: '12px Calibri,sans-serif',
        textAlign: 'center',
        offsetY: -15,
        fill: new Fill({
            color: [0,0,0,1]
        }),
        stroke: new Stroke({
            color: [255,255,255,0.5],
            width: 4
        })
    };
    baseTextStyle.text = text;
    return baseTextStyle;
}

function center(point){
    this.map.getView().setCenter(point);
}

function newLocation(data) {
//console.log("new point: ", data.detail.data);
    this.addPoint(data.detail.point, pointText(data.detail.data));
}

const pointText = d =>{
    return d[0] + " | " + d[1] + " | " + d[2];
}